import React from "react";
import Layout from "ps-components/Layout/Layout.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import termsStyle from "ps-assets/jss/termsOfUsageStyle.jsx";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import GridItem from "components/Grid/GridItem.jsx";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const PreventiveHealthCheckup = ({ classes, data }) => {
  var image = data.allStrapiImages.edges[0].node.content.childImageSharp.fluid;

  <StaticQuery
    query={graphql`
      query PreventiveHealthCheckup {
        allStrapiImages(filter: { category: { eq: "health_checkup_pages" } }) {
          edges {
            node {
              content {
                publicURL
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        allStrapiEntities(
          filter: { category: { eq: "preventive_health_checkup" } }
        ) {
          edges {
            node {
              title
              description
              summary
            }
          }
        }
      }
    `}
  />;

  return (
    <Layout image2={image} small>
      <Helmet key="helmetTags">
    <title>Preventive Health CheckUp| StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `preventive-health-checkup`}
      />
      <meta name="title" content="Preventive Health Checkup | StarHealth.in" />
      <meta name="twitter:title" content="Preventive Health Checkup | StarHealth.in" />
      <meta
        name="description"
        content="Star Preventive Health Checkup "
      />
      <meta
        name="keywords"
        content="Star Health Health Checkup at Home, Star Health checkup packages, Star Health Preventive Health Checkup"
      />
      <meta property="og:title" content="Preventive Health Checkup | StarHealth.in" />
      <meta property="og:description" content="Star Preventive Health Checkup " />
      <meta property="og:url" content={url + `preventive-health-checkup`}/>
      <meta name="Preventive Health Checkup | StarHealth.in" />
      <meta name="twitter:description" content="Star Preventive Health Checkup " />
      <meta property="twitter:url" content={url + `preventive-health-checkup`} />
    </Helmet>
  )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: `100px` }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={10}
                className={`${classes.mrAuto} ${classes.mlAuto}`}
              >
                {data.allStrapiEntities.edges.map(edge => {
                  return (
                    <>
                      <h2 style={{ textAlign: `center` }}>
                        <b>{edge.node.title}</b>
                      </h2>
                      <h5
                        className={classes.description}
                        style={{ textAlign: `justify` }}
                      >
                        {edge.node.summary}
                      </h5>
                      <Markdown>{edge.node.description}</Markdown>
                    </>
                  );
                })}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

PreventiveHealthCheckup.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(termsStyle)(PreventiveHealthCheckup);
